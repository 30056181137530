import React from 'react'
import { Container } from 'react-bootstrap'


function Dashboard() {
  return (
    <Container className='mt-3'>
        <h1>𝘿𝙖𝙨𝙝𝙗𝙤𝙖𝙧𝙙</h1>
        
    </Container>
  )
}

export default Dashboard